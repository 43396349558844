<template>
  <div>
    <a
      @click="goBackLink()"
      class="d-flex gap-1 align-items-center text-secondary"
      ><i class="bi bi-arrow-left-circle-fill fs-5"></i> Voltar</a
    >
  </div>
</template>

<script>
export default {
  name: "BreadCrumb",
  components: {},
  methods: {
    goBackLink() {
      let bc = this.$router.currentRoute.value.meta.breadcrumb.filter((b) => {
        return !b.current
      });
      
      bc = bc.length? bc.pop()['routeName'] : 'Dashboard';
      
      this.$router.push({ name: bc });
    },
  },
};
</script>

<style scoped>
div > a {
  width: 70px;
  cursor: pointer;
  text-decoration: none;
}
div > a:hover {
  cursor: pointer;
  text-decoration: underline;
}
</style>