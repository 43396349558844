<template>
  <TemplateIntegration />
</template>

<script>
import TemplateIntegration from "@/views/TemplateIntegration";

export default {
  name: "App",
  components: {
    TemplateIntegration,
  },
  
};
</script>

<style>
.title-showlist {
    font-size: 28px;
    padding-bottom: 50px;
    display: inline-flex;
    gap: 10px;
    align-items: center;        
}
.border-toast-alert {
  border: 1px solid red;
}
.border-toast-success {
  border: 1px solid green;
}
.border-toast-alert .swal2-timer-progress-bar {
  background: red !important;
}
.border-toast-success .swal2-timer-progress-bar {
  background: green !important;
}
</style>
