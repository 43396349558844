<template>
  <div class="text-left d-flex gap-3">
    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="offcanvas"
      data-bs-target="#offcanvasDarkNavbar"
      aria-controls="offcanvasDarkNavbar"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <span class="navbar-brand text-start">{{ title }}</span>
  </div>
  <div class="">
    <img class="cr-logo pe-3" src="@/assets/logo.png" alt="CR" />
    <div class="btn-group">
      <button
        type="button"
        class="btn btn-secondary dropdown-toggle user-profile"
        data-bs-toggle="dropdown"
        data-bs-display="static"
        aria-expanded="false"
      >
        <i class="bi bi-person-circle fs-5"></i
        >{{ session && session.user ? session.user.name : "" }} {{
          session && session.user.stores.length
            ? `(${session.user.stores[0].store.name.toUpperCase()})`
            : ""
        }}
      </button>
      <ul class="dropdown-menu dropdown-menu-dark dropdown-menu-lg-end">
        <li>
          <router-link class="dropdown-item" :to="{ name: 'UserProfile' }">
            <i class="bi bi-person-fill pe-2"></i>Conta
          </router-link>
        </li>
        <li>
          <a class="dropdown-item logout" @click.stop.prevent="logout()">
            <i class="bi bi-box-arrow-right pe-2"></i>
            Sair</a
          >
        </li>
      </ul>
    </div>
  </div>
  <div
    class="offcanvas offcanvas-start text-bg-dark"
    tabindex="-1"
    id="offcanvasDarkNavbar"
    aria-labelledby="offcanvasDarkNavbarLabel"
  >
    <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="offcanvasDarkNavbarLabel">
        <router-link
          class="nav-link"
          aria-current="page"
          :to="{ name: 'Dashboard' }"
        >
          Dashboard
        </router-link>
      </h5>
      <button
        type="button"
        class="btn-close btn-close-white"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      ></button>
    </div>
    <div class="offcanvas-body d-grid menu-lateral">
      <MenuList :hasStores="hasStores"/>
      <div class="align-self-end text-center">
        <div class="btn-group dropup">
          <button
            type="button"
            class="btn btn-secondary dropdown-toggle"
            data-bs-toggle="dropdown"
            data-bs-display="static"
            aria-expanded="false"
          >
            <i class="bi bi-person-circle fs-4"></i>
          </button>
          <ul class="dropdown-menu dropdown-menu-dark">
            <li>
              <router-link class="dropdown-item" :to="{ name: 'UserProfile' }">
                <i class="bi bi-person-fill pe-2"></i>Conta
              </router-link>
            </li>
            <li>
              <a class="dropdown-item logout" @click.stop.prevent="logout()"
                ><i class="bi bi-box-arrow-right pe-2"></i>Sair</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { postLogout } from "@/services/LoginApi";
import Cookies from "js-cookie";
import MenuList from "@/components/Menu/MenuList.vue";

export default {
  name: "MainMenu",
  components: {
    MenuList
  },
  data() {
    return {
      hasStores: false,
      title: "Dashboard de Integração",      
    };
  },
  computed: {
    ...mapState(["session", "authenticated"]),
  },
  created() {
    this.hasStores = this.session.user.stores.length != 0;    
  },
  methods: {
    ...mapMutations(["updateSession", "updateAuthenticated"]),
    logout() {
      postLogout()
        .then((res) => {
          if (res.code && res.code > 201) {
            this.toastNotification("Atenção", res);
            console.log("erro", res);
            return;
          }
          this.updateSession({});
          this.updateAuthenticated(false);
          window.localStorage.removeItem("vuex");
          Cookies.remove("cr_authenticated");
          this.$router.push({ name: "LoginForm" });
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    toastNotification(title, text) {
      let titleHtml = `<span class="d-flex justify-content-between ${
        title !== "Sucesso" ? "text-danger" : "text-success"
      }"><span>${title}!</span><i class="bi bi-${
        title !== "Sucesso" ? "exclamation-circle-fill" : "check-circle-fill"
      }"></i></span>`;
      let textHtml = `<span class="${
        title !== "Sucesso" ? "text-danger" : "text-dark"
      }">${text}</span`;

      this.$swal.fire({
        toast: true,
        showConfirmButton: false,
        timerProgressBar: true,
        position: "top-end",
        customClass: {
          popup: `shadow-sm border-toast-${
            title !== "Sucesso" ? "alert" : "success"
          }`,
        },
        timer: 5000,
        title: titleHtml,
        html: textHtml,
      });
    },
  },
};
</script>
<style scoped>

.cr-logo {
  max-width: 250px;
}
.logout {
  cursor: pointer;
}

.offcanvas {
  max-width: 300px;
}

.user-profile {
  display: flex;
  align-items: center;
}

.user-profile i {
  padding-right: 5px;
}
</style>