<template>
  <TemplateHeader v-if="authenticated" />
  <BreadCrumbWrapper v-if="authenticated" />
  <div class="container-fluid main-wrapper px-5 pt-1 pb-5">
    <router-view></router-view>
  </div>
  <TemplateFooter v-if="authenticated" />
</template>

<script>
import TemplateHeader from "@/views/template-parts/TemplateHeader";
import TemplateFooter from "@/views/template-parts/TemplateFooter";
import BreadCrumbWrapper from "@/components/BreadCrumb/BreadcrumbWrapper";
import { mapMutations, mapState } from "vuex";
import Cookies from "js-cookie";

export default {
  name: "TemplateIntegracao",
  components: {
    TemplateHeader,
    TemplateFooter,
    BreadCrumbWrapper,
  },
  computed: {
    ...mapState(["authenticated", "session"]),
  },
  methods: {
    ...mapMutations(["updateSession", "updateAuthenticated"]),
    checkCookie() {
      if (!Cookies.get("cr_authenticated")) {
        this.updateSession({});
        this.updateAuthenticated(false);
        window.localStorage.removeItem("vuex");
        this.$router.push({ name: "LoginForm" });
      }
    },
  },
  created() {
    this.checkCookie();
  },
};
</script>
<style scoped>
.main-wrapper {
  min-height: 70vh;
}
</style>
