<template>
  <div
    class="container-fluid breadcrumb-wrapper px-5 pt-3 pb-5"
    :class="{ 'not-show': !$store.state.authenticated }"
  >
    <BreadCrumb />
    <GoBack />
  </div>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb/BreadCrumb";
import GoBack from "@/components/BreadCrumb/GoBack";
export default {
  name: "BreadCrumbWrapper",
  components: {
    GoBack,
    BreadCrumb,
  },
};
</script>

<style scoped>
.not-show {
  display: none !important;
}
</style>