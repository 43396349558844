import VueSweetalert2 from "vue-sweetalert2";
import 'sweetalert2/dist/sweetalert2.min.css';

import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'


createApp(App)
.use(store)
.use(router)
.use(VueSweetalert2)
.mount('#app')


