<template>
  <footer class="py-3 bg-dark mt-auto">
    <div class="container-fluid text-center">
      <span class="text-muted"><img class="cr-logo pe-3" src="@/assets/logo.png" alt="CR"></span>
    </div>
  </footer>
</template>

<script>
export default {
  name: "TemplateFooter",
};
</script>

<style scoped>
.cr-logo {
  max-width: 250px;
}
.not-show {
  display: none !important;
}
</style>