import axios from "axios"

const baseURL = `${process.env.VUE_APP_BASE_URL_API}user/`;

export const postLogin = async (params) => {
  let { data } = await axios.post(`${baseURL}login`, params)
  return data
}

export const postLogout = async () => {

  let _session = window.localStorage.getItem('vuex') ? JSON.parse(window.localStorage.getItem('vuex')).session : '';
  let instance = axios.create({
    headers: {
      'Accept': 'appliocation/json',
      "Authorization": `Bearer ${_session.token}`
    }
  });
  let { data } = await instance.post(`${baseURL}logout`)
  return data
}