import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

export default createStore({
  state: {
    session: null,
    authenticated: false,
    deleteData: null,
    changeStatus: null
  },
  getters: {
    // getSession(state) {
    //   return state.session
    // },
    // getAuthenticated(state) {
    //   return state.authenticated
    // },
    // getDeteleData(state) {
    //   return state.deleteData
    // },
    // getChangeStatus(state) {
    //   return state.changeStatus
    // }
  },
  actions: {
    // setSession({ commit }, payload) {
    //   commit('updateSession', payload)
    // },
    // setAuthenticated({ commit }, payload) {
    //   commit('updateAuthenticated', payload)
    // },
    // setDeleteData({ commit }, payload) {
    //   commit('updateDeleteData', payload)
    // },
    // setChangeStatus({ commit }, payload) {
    //   commit('updateChangeStatus', payload)
    // },
  },
  mutations: {
    updateUserInfo(state, payload) {
      state.session.user.name = payload.name
      state.session.user.email = payload.email
    },
    updateSession(state, payload) {
      state.session = { ...payload }
    },
    updateAuthenticated(state, payload) {
      state.authenticated = payload
    },
    updateDeleteData(state, payload) {
      state.deleteData = payload
    },
    updateChangeStatus(state, payload) {
      state.changeStatus = payload
    }
  },
  modules: {
    
  },
  plugins: [vuexLocal.plugin]  
})
