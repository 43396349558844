import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store';
import Cookies from "js-cookie";

const routes = [
  {
    path: '/',
    alias: '/login',
    name: 'LoginForm',
    component: () => import('../views/LoginForm.vue'),
    beforeEnter: (to, from) => {
      if (Cookies.get('cr_authenticated')) {
        return false;
      }
    },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    meta: {
      breadcrumb: [
        {
          label: 'Início',
          current: true,
          routeName: 'Dashboard',
        },
      ]
    },
    component: () => import('../views/DashboardView.vue')
  },

  /* Store Product*/
  {
    path: '/store-products',
    name: 'StoreProductsList',
    meta: {
      breadcrumb: [
        {
          label: 'Início',
          current: false,
          routeName: 'Dashboard',
        },
        {
          label: 'Produtos',
          current: true,
          routeName: 'StoreProductsList',
        },
      ]
    },
    component: () => import('../views/StoreProducts/ShowList.vue')
  },

  /* Store Orders*/
  {
    path: '/store-orders',
    name: 'StoreOrdersList',
    meta: {
      breadcrumb: [
        {
          label: 'Início',
          current: false,
          routeName: 'Dashboard',
        },
        {
          label: 'Pedidos',
          current: true,
          routeName: 'StoreOrdersList',
        },
      ]
    },
    component: () => import('../views/StoreOrders/ShowList.vue')
  },

  /* Store */
  {
    path: '/store',
    name: 'StoreList',
    meta: {
      breadcrumb: [
        {
          label: 'Início',
          current: false,
          routeName: 'Dashboard',
        },
        {
          label: 'Lojas',
          current: true,
          routeName: 'StoreList',
        },
      ]
    },
    component: () => import('../views/Store/ShowList.vue')
  },
  {
    path: '/store/create',
    name: 'CreateStore',
    meta: {
      breadcrumb: [
        {
          label: 'Início',
          current: false,
          routeName: 'Dashboard',
        },
        {
          label: 'Lojas',
          current: false,
          routeName: 'StoreList',
        },
        {
          label: 'Cadastro de Loja',
          current: true,
          routeName: 'CreateStore',
        },
      ]
    },
    component: () => import('../views/Store/FormPage.vue')
  },
  {
    path: '/store/edit/:_document',
    name: 'EditStore',
    props: true,
    meta: {
      breadcrumb: [
        {
          label: 'Início',
          current: false,
          routeName: 'Dashboard',
        },
        {
          label: 'Cadastro de Loja',
          current: false,
          routeName: 'StoreList',
        },
        {
          label: 'Edição de Loja',
          current: true,
          routeName: 'EditStore',
        },
      ]
    },
    component: () => import('../views/Store/FormPage.vue')
  },
  /* StoreTokens */
  {
    path: '/store/tokens',
    name: 'TokensList',
    meta: {
      breadcrumb: [
        {
          label: 'Início',
          current: false,
          routeName: 'Dashboard',
        },
        {
          label: 'Tokens',
          current: true,
          routeName: 'TokensList',
        }
      ]
    },
    component: () => import('../views/StoreTokens/ShowList.vue')
  },
  {
    path: '/store/tokens/create',
    name: 'CreateTokens',
    meta: {
      breadcrumb: [
        {
          label: 'Início',
          current: false,
          routeName: 'Dashboard',
        },
        {
          label: 'Tokens',
          current: false,
          routeName: 'TokensList',
        },
        {
          label: 'Cadastro de Token',
          current: true,
          routeName: 'CreateTokens',
        }
      ]
    },
    component: () => import('../views/StoreTokens/FormPage.vue')
  },
  {
    path: '/store/tokens/edit/:_storeid',
    name: 'EditTokens',
    props: true,
    meta: {
      breadcrumb: [
        {
          label: 'Início',
          current: false,
          routeName: 'Dashboard',
        },
        {
          label: 'Cadastro de Token',
          current: false,
          routeName: 'TokensList',
        },
        {
          label: 'Edição de Token',
          current: true,
          routeName: 'EditTokens',
        }
      ]
    },
    component: () => import('../views/StoreTokens/FormPage.vue')
  },

  /* Integration */
  {
    path: '/integration-groups',
    name: 'IntegrationGroupsList',
    meta: {
      breadcrumb: [
        {
          label: 'Início',
          current: false,
          routeName: 'Dashboard',
        },
        {
          label: 'Grupos de Integração',
          current: true,
          routeName: 'IntegrationGroupsList',
        }
      ]
    },
    component: () => import('../views/IntegrationGroups/ShowList.vue')
  },
  {
    path: '/integration-groups/create',
    name: 'CreateIntegrationGroup',
    meta: {
      breadcrumb: [
        {
          label: 'Início',
          current: false,
          routeName: 'Dashboard',
          order: 1
        },
        {
          label: 'Grupos de Integração',
          current: false,
          routeName: 'IntegrationGroupsList',
          order: 2
        },
        {
          label: 'Cadastro de Integração',
          current: true,
          routeName: 'CreateIntegrationGroup',
          order: 3
        }
      ]
    },
    component: () => import('../views/IntegrationGroups/FormPage.vue')
  },
  {
    path: '/integration-groups/edit/:_id',
    name: 'EditIntegrationGroup',
    props: true,
    meta: {
      breadcrumb: [
        {
          label: 'Início',
          current: false,
          routeName: 'Dashboard',
        },
        {
          label: 'Grupos de Integração',
          current: false,
          routeName: 'IntegrationGroupsList',
        },
        {
          label: 'Edição da Integração',
          current: true,
          routeName: 'EditIntegrationGroup',
        }
      ]
    },
    component: () => import('../views/IntegrationGroups/FormPage.vue')
  },

  /* Integration Params */
  {
    path: '/integration-params',
    name: 'IntegrationParamsList',
    meta: {
      breadcrumb: [
        {
          label: 'Início',
          current: false,
          routeName: 'Dashboard',
        },
        {
          label: 'Parâmetros de Integração',
          current: true,
          routeName: 'IntegrationParamsList',
        }
      ]
    },
    component: () => import('../views/IntegrationParams/ShowList.vue')
  },
  {
    path: '/integration-params/create',
    name: 'CreateIntegrationParams',
    meta: {
      breadcrumb: [
        {
          label: 'Início',
          current: false,
          routeName: 'Dashboard',
        },
        {
          label: 'Parâmetros de Integração',
          current: false,
          routeName: 'IntegrationParamsList',
        },
        {
          label: 'Cadastro de Parâmetro',
          current: true,
          routeName: 'CreateIntegrationParams',
        }
      ]
    },
    component: () => import('../views/IntegrationParams/FormPage.vue')
  },
  {
    path: '/integration-params/edit/:_id',
    name: 'EditIntegrationParams',
    props: true,
    meta: {
      breadcrumb: [
        {
          label: 'Início',
          current: false,
          routeName: 'Dashboard',
        },
        {
          label: 'Parâmetros de Integração',
          current: false,
          routeName: 'IntegrationParamsList',
        },
        {
          label: 'Editar',
          current: true,
          routeName: 'EditIntegrationParams',
        }
      ]
    },
    component: () => import('../views/IntegrationParams/FormPage.vue')
  },

  /* Integration Params Values*/
  /* {
    path: '/store-integration',
    name: 'StoreIntegrationList',
    meta: {
      breadcrumb: [
        {
          label: 'Início',
          current: false,
          routeName: 'Dashboard',
        },
        {
          label: 'Valores dos Parâmetros de Integração',
          current: true,
          routeName: 'StoreIntegrationList',
        }
      ]
    },
    component: () => import('../views/IntegrationParamsValues/ShowList.vue')
  }, */
  {
    path: '/store-integration',
    name: 'EditStoreIntegration',
    meta: {
      breadcrumb: [
        {
          label: 'Início',
          current: false,
          routeName: 'Dashboard',
        }
      ]
    },
    component: () => import('../views/IntegrationParamsValues/FormPage.vue')
  },
  /*{
    path: '/store-integration/edit/:_id',
    name: 'EditStoreIntegration',
    props: true,
    meta: {
      breadcrumb: [
        {
          label: 'Início',
          current: false,
          routeName: 'Dashboard',
        },
        {
          label: 'Editar',
          current: true,
          routeName: 'EditStoreIntegration',
        }
      ]
    },
    component: () => import('../views/IntegrationParamsValues/FormPage.vue')
  }, */

  /* Users */
  {
    path: '/users',
    name: 'UsersList',
    meta: {
      breadcrumb: [
        {
          label: 'Início',
          current: false,
          routeName: 'Dashboard',
        },
        {
          label: 'Usuários',
          current: true,
          routeName: 'UsersList',
        }
      ]
    },
    component: () => import('../views/Users/ShowList.vue')
  },
  {
    path: '/users/create',
    name: 'CreateUser',
    meta: {
      breadcrumb: [
        {
          label: 'Início',
          current: false,
          routeName: 'Dashboard',
        },
        {
          label: 'Usuários',
          current: false,
          routeName: 'UsersList',
        },
        {
          label: 'Cadastrar',
          current: true,
          routeName: 'CreateUser',
        }
      ]
    },
    component: () => import('../views/Users/FormPage.vue')
  },
  {
    path: '/users/edit/:_id',
    name: 'EditUser',
    props: true,
    meta: {
      breadcrumb: [
        {
          label: 'Início',
          current: false,
          routeName: 'Dashboard',
        },
        {
          label: 'Usuários',
          current: false,
          routeName: 'UsersList',
        },
        {
          label: 'Editar',
          current: true,
          routeName: 'EditUser',
        }
      ]
    },
    component: () => import('../views/Users/FormPage.vue')
  },

  {
    path: '/user',
    name: 'UserProfile',
    meta: {
      breadcrumb: [
        {
          label: 'Início',
          current: false,
          routeName: 'Dashboard',
        },
        {
          label: 'Perfil do Usuário',
          current: true,
          routeName: 'UserProfile',
        },
      ]
    },
    component: () => import('../views/User/FormPage.vue')
  },


  /* Logs */
  {
    path: '/logtracer',
    name: 'LogTracerList',
    meta: {
      breadcrumb: [
        {
          label: 'Início',
          current: false,
          routeName: 'Dashboard',
        },
        {
          label: 'Logs',
          current: true,
          routeName: 'LogTracerList',
        }
      ]
    },
    component: () => import('../views/LogTracer/ShowList.vue')
  },
  {
    path: '/logtracer/:_id',
    name: 'ReadLogTracer',
    props: true,
    meta: {
      breadcrumb: [
        {
          label: 'Início',
          current: false,
          routeName: 'Dashboard',
        },
        {
          label: 'Logs',
          current: false,
          routeName: 'LogTracerList',
        },
        {
          label: 'Ver Log',
          current: true,
          routeName: 'ReadLogTracer',
        }
      ]
    },
    component: () => import('../views/LogTracer/FormPage.vue')
  },


  /* 404 page */
  {
    path: '/:catchAll(.*)',
    name: '404',
    meta: {
      breadcrumb: [
        {
          label: 'Início',
          current: false,
          routeName: 'Dashboard',
        },
        {
          label: '404',
          current: true,
          routeName: '404',
        }
      ]
    },
    component: () => import('../views/NotFound.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  if (!Cookies.get('cr_authenticated') && to.name !== 'LoginForm') {
    next({ name: 'LoginForm' })
  } else {
    await store.restored;
    next();
  }
});

export default router