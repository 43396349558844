<template>
  <ul class="navbar-nav justify-content-end flex-grow-1 pe-3 align-self-start">
    <template v-for="(group, idx) in menuGroup" :key="idx">
      <li class="nav-item nav-title">{{ group }}</li>
      <template v-for="(item, idxItem) in menuItems" :key="idxItem">
        <li class="nav-item" v-if="item.group === group && item.show">
          <router-link
            class="nav-link"
            aria-current="page"
            :to="{ name: item.routeName }"
            >{{ item.text }}</router-link
          >
        </li>
      </template>
    </template>
  </ul>
</template>
<script>
export default {
  name: "MenuList",
  props: ["hasStores"],
  data() {
    return {
      menuGroup: ["LOJA", "INTEGRAÇÃO", "USUÁRIOS", "LOGS"],
      menuItems: [
        {
          group: "LOJA",
          show: true,
          role: [],
          routeName: "StoreList",
          text: "Listagem - Lojas",
        },
        {
          group: "LOJA",
          show: true,
          role: [],
          routeName: "CreateStore",
          text: "Cadastro - Loja",
        },
        {
          group: "LOJA",
          show: true,
          role: [],
          routeName: "TokensList",
          text: "Listagem - Tokens",
        },
        {
          group: "LOJA",
          show: this.hasStores,
          role: [],
          routeName: "CreateTokens",
          text: "Cadastro - Tokens",
        },
        {
          group: "LOJA",
          show: true,
          role: [],
          routeName: "StoreProductsList",
          text: "Listagem - Produtos",
        },
        {
          group: "LOJA",
          show: true,
          role: [],
          routeName: "StoreOrdersList",
          text: "Listagem - Pedidos",
        },
        {
          group: "INTEGRAÇÃO",
          show: true,
          role: [],
          routeName: "EditStoreIntegration",
          text: "Integração - Lojista",
        },
        {
          group: "INTEGRAÇÃO",
          show: true,
          role: [],
          routeName: "IntegrationGroupsList",
          text: "Listagem - Grupos",
        },
        {
          group: "INTEGRAÇÃO",
          show: true,
          role: [],
          routeName: "CreateIntegrationGroup",
          text: "Cadastro - Grupo",
        },
        {
          group: "INTEGRAÇÃO",
          show: true,
          role: [],
          routeName: "IntegrationParamsList",
          text: "Listagem - Parâmetros",
        },
        {
          group: "INTEGRAÇÃO",
          show: true,
          role: [],
          routeName: "CreateIntegrationParams",
          text: "Cadastro - Parâmetros",
        },
        {
          group: "USUÁRIOS",
          show: true,
          role: [],
          routeName: "UsersList",
          text: "Listagem",
        },

        {
          group: "USUÁRIOS",
          show: true,
          role: [],
          routeName: "CreateUser",
          text: "Cadastro",
        },
        {
          group: "LOGS",
          show: true,
          role: [],
          routeName: "LogTracerList",
          text: "Listagem",
        },
      ],
    };
  },
};
</script>

<style scoped>
.navbar-nav .nav-title:not(:nth-child(1)) {
  margin-top: 10px;
}

.nav-title {
  background-color: #6c757d;
  font-weight: 700;
  border-radius: 5px 5px 0 0;
}
.menu-lateral > ul > li {
  padding-inline: 10px;
}
.menu-lateral .nav-link {
  color: #6c757d;
}
.menu-lateral .nav-link:hover {
  color: #fff;
}
</style>