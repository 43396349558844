<template>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li
        class="breadcrumb-item"
        :class="breadcrumb.current ? 'active' : ''"
        v-for="(breadcrumb, idx) in $router.currentRoute.value.meta.breadcrumb"
        :key="idx"
      >
        <router-link aria-current="page" :to="{ name: breadcrumb.routeName }">
          {{ breadcrumb.label }}
        </router-link>
      </li>
    </ol>
  </nav>
</template>
<script>
export default {
  name: "BreadCrumb",
};
</script>

<style scoped>
nav a {
  color: rgba(var(--bs-secondary-rgb), 1);
  text-decoration: none;
  font-size: 14px;
}
nav a:hover {
  text-decoration: underline;
}
nav li.active a {
  font-weight: 700;
}
</style>