<template>
  <header
    class="navbar navbar-dark bg-dark">
    <div class="container-fluid">
      <MainMenu />
    </div>
  </header>
</template>

<script>
import MainMenu from "@/components/Menu/MainMenu";

export default {
  name: "TemplateHeader",
  components: {
    MainMenu,
  },
  // created() {
  //   if (!this.$store.state.authenticated) {
  //     this.$router.push({name: 'LoginForm'})
  //   }
  // },
};
</script>

<style scoped>
.not-show {
  display: none !important;
}
header {
  max-height: 70px;
}
</style>